<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
  *{
    margin: 0;
    padding: 0;
  }
  html,body{
    height: 100%;
  }
  #app {
    background-image: url('./assets/bg.png');
    height: 100%;
  }
</style>
