import { createRouter, createWebHistory } from 'vue-router'

//路由数组
const routes = [
  {
    //基本格式
      path: "/",
      name: "发布页",
      component: ()=>import("../components/Publish.vue")
  },
  {
    //基本格式
      path: "/login",
      name: "登录页",
      component: ()=>import("../components/Login.vue")
  },
]
  
//路由对象
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes //上面的路由数组
})

router.beforeEach((to, from, next) => {
  console.log(to, from, next)

  if( to.path == '/login' ) {
    next();
  }
  //进行判断，如果to路径没有匹配到现有的任何一个路由
  //作用：当to的路由为空时不跳转，同时当from的路由也为空时，则跳转到404页面
  const token = localStorage.getItem('token');
  if (!token){
    next("/login");
  }else {
    //如果to的路由名称不为空，则进行跳转
    next();
  }
})
  
//导出路由对象，在main.js中引用
export default router